const invoiceRouter = {
  route: null,
  name: null,
  title: '发票管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  isElementIcon: false,
  filePath: 'view/invoice/', // 文件路径
  order: 8,
  inNav: true,
  permission: ['创建发票'],
  children: [
    {
      title: '发票查重',
      type: 'view',
      name: 'InvoiceAdd',
      route: '/invoice/add',
      filePath: 'view/invoice/invoice-add.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
      permission: ['创建发票'],
    },
    {
      title: '查询记录',
      type: 'view',
      name: 'InvoiceList',
      route: '/invoice/list',
      filePath: 'view/invoice/invoice-list.vue',
      inNav: true,
      icon: 'iconfont icon-table1',
      isElementIcon: false,
      permission: ['创建发票'],
    },
    {
      title: '发票导入导出',
      type: 'view',
      name: 'InvoiceExport',
      route: '/invoice/export',
      filePath: 'view/invoice/invoice-export.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
      isElementIcon: false,
      permission: ['发票导出'],
    },
    // {
    //   title: '我的发票',
    //   type: 'view',
    //   name: 'InvoiceMy',
    //   route: '/invoice/my',
    //   filePath: 'view/invoice/invoice-my.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-tushuguanli',
    //   isElementIcon: false,
    // },
  ],
}

export default invoiceRouter
