const adminDistRouter = {
  route: null,
  name: null,
  title: '渠道商管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  isElementIcon: false,
  filePath: 'view/admin/admin-dist/', // 文件路径
  order: 60,
  inNav: true,
  permission: ['超级管理员独有权限'],
  children: [
    {
      title: '渠道商下级公司',
      type: 'view',
      name: 'ComListByDist',
      route: '/admin-dist/com-list-by-dist',
      // filePath: 'view/admin/admin-dist/distributor-list.vue',
      filePath: 'view/admin/admin-dist/com-list-by-dist.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      permission: ['超级管理员独有权限'],
    },
    {
      title: '渠道商列表',
      type: 'view',
      name: 'DistributorList',
      route: '/admin-dist/list',
      // filePath: 'view/admin/admin-dist/distributor-list.vue',
      filePath: 'view/admin/admin-dist/distributor-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      permission: ['超级管理员独有权限'],
    },

    {
      title: '创建渠道商',
      type: 'view',
      name: 'DistributorAdd',
      route: '/admin-dist/distributor-add',
      filePath: 'view/admin/admin-dist/distributor-create.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
      permission: ['超级管理员独有权限'],
    },
  ],
}

export default adminDistRouter
