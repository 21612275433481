const comUserRouter = {
  route: '/admin/user',
  name: null,
  title: '用户管理', //名称改了， 要改util.js 中的对应的，超管看的权限，只要父级即可
  type: 'folder',
  icon: 'iconfont icon-huiyuanguanli',
  isElementIcon: false,
  filePath: 'view/admin/com-user/',
  order: 86,
  inNav: true,
  // permission: ['超级管理员独有权限'],
  permission: ['查询本公司所有用户'],
  children: [
    {
      title: '用户列表',
      type: 'view',
      name: 'ComUserList',
      route: '/admin/com-user/list',
      filePath: 'view/admin/com-user/user-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      permission: ['查询本公司所有用户'],
    },
    {
      title: '添加用户',
      type: 'view',
      inNav: true,
      route: '/admin/com-user/add',
      icon: 'iconfont icon-add',
      isElementIcon: false,
      name: 'ComUserCreate',
      filePath: 'view/admin/com-user/user-create.vue',
      permission: ['查询本公司所有用户'],
    },
  ],
}

export default comUserRouter
