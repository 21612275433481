const adminGroupRouter = {
  route: '/admin/group/list',
  name: null,
  title: '权限分组管理', //名称改了， 要改util.js 中的对应的，超管看的权限
  type: 'tab',
  icon: 'iconfont icon-yunyingguanli_fuwufenzuguanli',
  isElementIcon: false,
  filePath: 'view/admin/group',
  order: 89,
  inNav: true,
  permission: ['超级管理员独有权限'],
  // permission: ['查询本公司所有用户'],
  children: [
    {
      route: '/admin/group/list',
      type: 'view',
      name: 'groupList',
      inNav: true,
      filePath: 'view/admin/group/group-list.vue',
      title: '分组列表',
      icon: 'iconfont icon-huiyuanguanli',
      permission: ['超级管理员独有权限'],
    },
    {
      route: '/admin/group/add',
      type: 'view',
      name: 'GroupCreate',
      filePath: 'view/admin/group/group-create.vue',
      inNav: true,
      title: '添加分组',
      icon: 'iconfont icon-add',
      permission: ['超级管理员独有权限'],
    },
    {
      route: '/admin/group/edit',
      type: 'view',
      name: 'GroupEdit',
      filePath: 'view/admin/group/group-edit.vue',
      inNav: false,
      title: '修改分组',
      icon: 'iconfont icon-add',
      permission: ['超级管理员独有权限'],
    },
  ],
}

export default adminGroupRouter
