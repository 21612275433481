const DistComRouter = {
  route: null,
  name: null,
  title: '公司管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  isElementIcon: false,
  filePath: 'view/admin/dist-com/', // 文件路径
  order: 60,
  inNav: true,
  permission: ['渠道商创建公司'],
  children: [
    {
      title: '公司列表',
      type: 'view',
      name: 'companyList',
      route: '/dist-com/list',
      filePath: 'view/admin/dist-com/company-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      permission: ['渠道商注册管理员用户'],
    },
    {
      title: '公司管理员列表',
      type: 'view',
      name: 'DistComList',
      route: '/dist-com/admin-user-list',
      filePath: 'view/admin/dist-com/admin-user-list.vue', //渠道商创建用户
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      permission: ['渠道商注册管理员用户'],
    },
    {
      title: '创建公司管理员',
      type: 'view',
      name: 'DistComUserAdd',
      route: '/dist-com/admin-user-add',
      filePath: 'view/admin/dist-com/admin-user-create.vue', //渠道商创建用户
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
      permission: ['渠道商注册管理员用户'],
    },
  ],
}

export default DistComRouter
