import Utils from '@/lin/util/util'
import adminGroupRouter from './admin-group'
import invoiceConfig from './invoice' // 发票管理路由文件
import adminDistRouter from './admin-dist' // 超管 管理 渠道商路由文件
import DistComRouter from './dist-com' // 渠道商 管理 公司路由文件
import ComUserRouter from './com-user' // 管理员 管理 本公司用户路由文件
import pluginsConfig from './plugin'

// eslint-disable-next-line import/no-mutable-exports
let homeRouter = [
  {
    title: '首页',
    type: 'view',
    name: Symbol('about'),
    route: '/about',
    filePath: 'view/about/about-new.vue',
    inNav: true,
    icon: 'iconfont icon-iconset0103',
    isElementIcon: false,
    order: 1,
  },
  // {
  //   title: '日志管理',
  //   type: 'view',
  //   name: Symbol('log'),
  //   route: '/log',
  //   filePath: 'view/log/log.vue',
  //   inNav: true,
  //   icon: 'iconfont icon-rizhiguanli',
  //   isElementIcon: false,
  //   order: 20,
  //   permission: ['查询所有日志'],
  // },
  {
    title: '个人中心',
    type: 'view',
    name: Symbol('center'),
    route: '/center',
    filePath: 'view/center/center.vue',
    inNav: false,
    icon: 'iconfont icon-rizhiguanli',
    isElementIcon: false,
  },
  {
    title: '404',
    type: 'view',
    name: Symbol('404'),
    route: '/404',
    filePath: 'view/error-page/404.vue',
    inNav: false,
    icon: 'iconfont icon-rizhiguanli',
    isElementIcon: false,
  },
  invoiceConfig,
  adminDistRouter,
  DistComRouter,
  ComUserRouter,
  adminGroupRouter,
]

// 接入插件
// const plugins = [...pluginsConfig]
// filterPlugin(homeRouter)
// homeRouter = homeRouter.concat(plugins)

// 处理顺序
homeRouter = Utils.sortByOrder(homeRouter)
deepReduceName(homeRouter)

export default homeRouter

/**
 * 筛除已经被添加的插件
 */
function filterPlugin(data) {
  if (plugins.length === 0) {
    return
  }
  if (Array.isArray(data)) {
    data.forEach(item => {
      filterPlugin(item)
    })
  } else {
    const findResult = plugins.findIndex(item => data === item)
    if (findResult >= 0) {
      plugins.splice(findResult, 1)
    }
    if (data.children) {
      filterPlugin(data.children)
    }
  }
}

/**
 * 使用 Symbol 处理 name 字段, 保证唯一性
 */
function deepReduceName(target) {
  if (Array.isArray(target)) {
    target.forEach(item => {
      if (typeof item !== 'object') {
        return
      }
      deepReduceName(item)
    })
    return
  }
  if (typeof target === 'object') {
    if (typeof target.name !== 'symbol') {
      target.name = target.name || Utils.getRandomStr()
      target.name = Symbol(target.name)
    }

    if (Array.isArray(target.children)) {
      target.children.forEach(item => {
        if (typeof item !== 'object') {
          return
        }
        deepReduceName(item)
      })
    }
  }
}
